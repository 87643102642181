
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { drawerWidth } from './AppBarLayout';
import MuiDrawer from '@mui/material/Drawer';
import { NavLink, useLocation } from 'react-router-dom';
import { Divider, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import { useAppDispatch, useAppSelector } from '../Infrastructure/Store/hooks';
import { setAppTitle, toggleDrawer } from '../Store/AppSlice';
import { LanguageConsumer } from '../Infrastructure/Internationalisation/TranslationService';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import { useTobiTour } from '../Infrastructure/Hooks/Global/useTobiTour';
import { useGlobalSteps } from '../Infrastructure/OnlineHelp/Global/OnlineHelpGlobalSteps';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ChangeCircleTwoToneIcon from '@mui/icons-material/ChangeCircleTwoTone';
import { ChangeCircle, ChevronLeft, ChevronRight, Dashboard, Edit, PeopleAlt, Skateboarding, SpaceDashboard, Timeline, ViewTimeline, VpnKey } from '@mui/icons-material';
import { Tilt } from 'react-tilt'
import { useEffect } from 'react';

const defaultOptions = {
	reverse:        false,  // reverse the tilt direction
	max:            35,     // max tilt rotation (degrees)
	perspective:    1000,   // Transform perspective, the lower the more extreme the tilt gets.
	scale:          1.1,    // 2 = 200%, 1.5 = 150%, etc..
	speed:          1000,   // Speed of the enter/exit transition
	transition:     true,   // Set a transition on enter/exit.
	axis:           null,   // What axis should be disabled. Can be X or Y.
	reset:          true,    // If the tilt effect has to be reset on exit.
	easing:         "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: 10, //drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const DrawerLayout = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const open = useAppSelector(store => store.global.drawerOpen);
  const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');
  const location = useLocation();

  const matchesBigDevices = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    console.log("fast")
    if(!matchesBigDevices && open){
      console.log("Sollte !!!")
      dispatch(toggleDrawer())
    }

  }, [matchesBigDevices])


  const navigationList = [
    { name: 'MainMenuHome', route: '/', icon: <PeopleAlt />, className: 'global-menu-customers'},
    { name: 'MainMenuProjects', route: '/projects', icon: <SpaceDashboard />, className: 'global-menu-customers'},
    { name: 'MainMenuCv', route: '/cv', icon: <Timeline />, className: 'global-menu-customers'},
    { name: 'MainMenuSkills', route: '/skills', icon: <Skateboarding />, className: 'global-menu-customers'},
  ];

  return (<LanguageConsumer>
    {({getTranslatedText}) =>
    <>
    <Drawer  variant="permanent" open={open} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <DrawerHeader sx={{flexGrow: 0, textAlign: 'center', justifyContent: 'center', p: 3, pb: 0}}>
        {open && <Tilt options={defaultOptions}> <Typography fontFamily={'Satisfy, cursive'} variant='h1' fontWeight={800}>TM<span style={{color: theme.palette.info.light}}>.</span></Typography></Tilt>}

        <IconButton
             color="inherit"
             aria-label="open drawer"
             onClick={() => dispatch(toggleDrawer())}
             edge="start"
             sx={{
                position: !open ? 'flex' : 'absolute',
                top: 0,
                right: !open ? 2 : 0,
               ml: 1,
               color: theme.palette.darky.light,
             }}
           >
             {open ? (
               <ChevronLeft />
             ) : (
               <ChevronRight />
             )}
           </IconButton>
      </DrawerHeader>
      <List sx={{ flexGrow: 1, p:  open ? 4 : 1, pt: 2}} className={'global-menu'}>
        {navigationList.map((text, index) => (
          <Tooltip className={text.className} key={index} title={getTranslatedText(text.name)} placement={'right'}>
            <ListItem color='secondary' onClick={() => dispatch(setAppTitle(getTranslatedText(text.name)))} component={NavLink} to={text.route} disablePadding sx={{ display: 'block', borderRadius: 2 }}>
              <ListItemButton
                selected={text.route === location.pathname || (location.pathname.includes(text.route) && text.route !== '/')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  color: theme.palette.darky.main,
                  borderRadius: 2,
                  my: 1
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: theme.palette.darky.main
                  }}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText primary={<Typography fontWeight={800}>{getTranslatedText(text.name)}</Typography>} sx={{opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          </Tooltip>
        ))}

        {/* <Divider sx={{ my: 2 }} /> */}

      </List>
    </Drawer>
  </>
  }
    </LanguageConsumer>
  )
}