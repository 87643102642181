import { Routes, Route, useLocation } from "react-router-dom"
import { Login } from "./Features/Infrastructure/Login/Login"
import ProtectedRoute from "./Features/Infrastructure/Login/ProtectedRoute/ProtectedRoute"
import { SettingsCommon } from "./Features/MainMenus/Settings/Common/SettingsCommon"
import { Settings } from "./Features/MainMenus/Settings/Settings"
import { Profile } from "./Features/MainMenus/Profile/Profile"
import { Projects } from "./Features/MainMenus/Projects/Projects"
import { Skills } from "./Features/MainMenus/Skills/Skills"
import { AnimatePresence } from "framer-motion";
import { Privacy } from "./Features/MainMenus/Privacy/Privacy"
import { Imprint } from "./Features/MainMenus/Imprint/Imprint"


export const DepragRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <Routes location={location} key={location.pathname}>
                <Route path="/login" element={<Login />} />

                <Route element={<ProtectedRoute />}>

                    <Route path="/" element={<Profile />} />

                    <Route path="/projects" element={<Projects />} />

                    <Route path="/skills" element={<Skills />} />

                    <Route path="/privacy" element={<Privacy />} />

                    <Route path="/imprint" element={<Imprint />} />

                    <Route path="/settings" element={<Settings component={<SettingsCommon />} />} />
                    <Route path="/settings/industry" element={<Settings component={<SettingsCommon />} />} />

                </Route>

            </Routes>
        </AnimatePresence>
    )
}