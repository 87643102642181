
export interface ProjectListItem {
  id: number,
  projectName: string,
  title: string,
  content: string,
  images: string[],
  tags: string[],
  links: ProjectListItemLink[]
}

interface ProjectListItemLink {
  url: string,
  text: string
}

export const ProjectsList = [
    {
      id: 1,
      projectName: 'Smokebaron',
      title: 'ProjectSmokebaronTitle',
      content: 'ProjectSmokebaronContent',
      images: ['StadtAmberg.png'],
      tags: ['Ionic', 'Angular', 'Android', 'iOS'],
      links: [
        {url: 'https://smokebaron.com', text: 'smokebaron.com'}, 
      ]
    },
    {
      id: 2,
      projectName: 'StadtAmberg',
      title: 'ProjectStadtAmbergTitle',
      content: 'ProjectStadtAmbergContent',
      images: ['StadtAmberg.png'],
      tags: ['Ionic', 'Angular', 'Android', 'iOS'],
      links: [
        {url: 'https://apps.apple.com/de/app/stadt-amberg/id1059154988', text: 'iOS'}, 
        {url: 'https://play.google.com/store/apps/details?id=com.ionicframework.blogapp265257&hl=de&gl=US', text: 'Android'}
      ]
    },
    {
      id: 3,
      projectName: 'KulturschloßTheuern',
      title: 'ProjectKulturschloßTitle',
      content: 'ProjectKulturschloßContent',
      images: ['StadtAmberg.png'],
      tags: ['HTML5', 'CSS3', 'Wordpress'],
      links: [
        {url: 'https://kultur-schloss-theuern.de', text: 'kultur-schloss-theuern.de'}, 
      ]
    },
    {
      id: 4,
      projectName: 'Refit',
      title: 'ProjectRefitTitle',
      content: 'ProjectRefitContent',
      images: ['StadtAmberg.png'],
      tags: ['C#', 'Angular', 'JavaScript'],
      links: [
        {url: 'https://refit-amberg.de', text: 'refit-amberg.de'}, 
      ]
    }
  ] as ProjectListItem[]
  