
import { StepType } from '@reactour/tour';
import { createSlice } from '@reduxjs/toolkit';

type OnlineHelpType = 'global' | 'programs';

export interface OnlineHelpState {
  status: 'idle' | 'loading' | 'failed';
  open: boolean;
}

const initialState: OnlineHelpState = {
  status: 'idle',
  open: false,
};


export const onlineHelpSlice = createSlice({
  name: 'onlineHelp',
  initialState,
  reducers: {

  }
});

export const {  } = onlineHelpSlice.actions;


export default onlineHelpSlice.reducer;
