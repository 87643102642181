import { createSlice } from '@reduxjs/toolkit';

export interface ContactState {
  status: 'idle' | 'loading' | 'failed',
  open: boolean
}

const initialState: ContactState = {
  status: 'idle',
  open: false,
};


export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    toggleContact: (state) => {
      state.open = !Boolean(state.open);
    },
  }
});

export const { toggleContact} = contactSlice.actions;


export default contactSlice.reducer;
