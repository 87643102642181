import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';


export interface AppState {
  status: 'idle' | 'loading' | 'failed',
  drawerOpen: boolean,
  appTitle: string,
  projectDetailDrawerOpen: boolean,
  projectName: string,
  isLoading: boolean,
  profileDrawerOpen: boolean
}

const initialState: AppState = {
  status: 'idle',
  drawerOpen: false,
  appTitle: 'Process',
  projectDetailDrawerOpen: false,
  projectName: '',
  isLoading: true,
  profileDrawerOpen: false,
};


export const appSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setAppTitle: (state, action) => {
      state.appTitle = action.payload;
    },
    toggleDrawer: (state) => {
      state.drawerOpen = !Boolean(state.drawerOpen);
    },
    toggleProjectDetailDrawer: (state, action) => {
      state.projectDetailDrawerOpen = !Boolean(state.projectDetailDrawerOpen);
      state.projectName = state.projectName === '' ? action.payload : '';
    },
    toggleIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    toggleProfileDrawer: (state) => {
      state.profileDrawerOpen = !Boolean(state.profileDrawerOpen)
    }
  }
});

export const { toggleProfileDrawer, toggleIsLoading, toggleProjectDetailDrawer, setAppTitle, toggleDrawer } = appSlice.actions;


export default appSlice.reducer;
