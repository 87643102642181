
import { createSlice } from '@reduxjs/toolkit';


export interface Notificationstate {
  status: 'idle' | 'loading' | 'failed';
  open: boolean;
}

const initialState: Notificationstate = {
  status: 'idle',
  open: false,
};


export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleNotificationsDrawer: (state) => {
      state.open = !Boolean(state.open)
    }
  }
});

export const { toggleNotificationsDrawer } = notificationsSlice.actions;


export default notificationsSlice.reducer;
