import { Box, Button, Chip, Fade, Grid, Grow, Typography, useTheme } from "@mui/material";
import { Suspense, useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getTranslation, LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../Store/AppSlice";
import './Style/Projects.css'
import { CustomBox } from "../../../Layout/CustomBox";
import { ProjectsCards } from "./Components/ProjectsCards";
import { ProjectsList } from "./Api/ProjectsProvider";
import React from "react";
import Spline, { SplineEvent, SplineProps } from "@splinetool/react-spline";
import { Application } from "@splinetool/runtime";


export const Projects = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');
    const [activeProject, setActiveProject] = useState<number>(1);
    const [activeSpline, setActiveSpline] = useState<string>('Smokebaron')
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const refSpline = useRef(null);
    const [showIt, setShowIt] = useState<boolean>(false);

    useEffect(() => {
        dispatch(setAppTitle(getTranslation('MainMenuProjects')));
    }, [location, language, languageFileWasLoaded]);


    function onLoad(spline: Application) {
   
         spline.setSize(0, 0);
       
        setTimeout(function () {
            setShowIt(true);
             spline.setSize(window.innerWidth, window.innerHeight);
          
        }, 500);
    }


    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                    <Grid container direction={'row'} spacing={1} sx={{ ml: { xs: 2, md: 8, lg: 12 }, mt: 3 }}>

                        <Grid item xs={12} md={6} lg={7} sx={{ mt: { xs: 4, md: 0 }, height: 'calc(100vh - 140px)' }}>

                     
                        <Spline
                                ref={refSpline}
                                onLoad={onLoad}
                                renderOnDemand={true}
                                style={{
                                    // background: 'red',
                                    opacity: showIt ? 1 : 0, 
                                    transition: 'opacity 0.3s ease-in-out', 
                                    height: '100%', 
                                    top: 0, 
                                    left: 0, 
                                    width: `100%`, 
                                    zIndex: 100001 
                                }}
                                scene={`Projects/${activeSpline}.splinecode`} />
                                

                        </Grid>
                        <Grid item xs={12} md={6} lg={5} sx={{ mt: { xs: 4, md: -9 } }} alignItems={'center'} alignSelf={'center'}>
                            {ProjectsList.map((project, projectKey) => {
                                var differenceBetweenIdAndActive = Math.abs(activeProject - project.id);
                                var isActive = project.id === activeProject;
                                return (
                                    <CustomBox
                                        className="projectList"
                                        onClick={() => {   
                                            if(activeProject !== project.id){
                                                setShowIt(false);
                                                setTimeout(() => {
                                                   setActiveProject(project.id);
                                                   setActiveSpline(project.projectName);
                                                }, 300)   
                                            }         
                                        }}
                                        key={`project-${projectKey}`}
                                        sx={{
                                            opacity: isActive ? 1 : (.3 / (differenceBetweenIdAndActive)),
                                            transition: 'all 0.2s ease-in-out',
                                            mr: 12,
                                            mt: projectKey > 0 ? 2 : 0,
                                            transform: `scale(${isActive ? 1.05 : 1})`,
                                            // border: `${isActive ? 3 : 0}px solid ${theme.palette.themey.main}`,
                                        }}>
                                        <Grid container direction={'row'} spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" fontWeight={300}>
                                                    {getTranslatedText(project.title)}
                                                    <img src='logo_tm.png' style={{opacity: isActive ? 1 : 0, transition: 'opacity 0.3s ease-in', height: 30, float: 'right', marginRight: 10}} />
                                                </Typography>
                                               
                                            </Grid>
                                            {isActive &&<Fade in={isActive}><Grid item xs={12}>
                                                {project.tags && project.tags.length > 0 && project.tags.map((tag, tagKey) => {
                                                    return(
                                                        <Chip label={tag} title={tag} key={`tag-${tagKey}`} sx={{mr: 1}} />
                                                    )
                                                })}
                                            </Grid></Fade>}

                                            {isActive && <Fade in={isActive}><Grid item xs={12}>
                                                {project.tags && project.links.length > 0 && project.links.map((link, linkKey) => {
                                                    return(
                                                        <Button color="darky" component={NavLink} to={link.url} target="_blank" variant="outlined" key={`link-${linkKey}`} sx={{mr: 1}}>{getTranslatedText(link.text)}</Button>
                                                    )
                                                })}
                                            </Grid>
                                            </Fade>}
                                        </Grid>
                                    </CustomBox>
                                )
                            })}

                        </Grid>
                    </Grid>
                </>
            }
        </LanguageConsumer>
    );
}