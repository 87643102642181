
import { BoxProps, StyledComponentProps, Box, useTheme, Typography } from "@mui/material";
import { useAppSelector } from "../Infrastructure/Store/hooks";

interface CustomBoxProps {
  noShadow?: boolean
}

interface Props extends BoxProps, CustomBoxProps, StyledComponentProps {}

export const CustomBoxHeader = ({text, ...props}) => {

  return(
    <Typography fontWeight={800} sx={{mt: -1, mb: 2.5, ...props.sx}} color="darky">{text}</Typography>
  )
}

export const CustomBox = ({ children, noShadow = false, ...props }: Props) => {
  const theme = useTheme();
  const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');

  return <Box
    component="div"
    className={props.className}
    onClick={props.onClick}
    onContextMenu={props.onContextMenu}
    sx={{
      boxShadow: noShadow ? 'none' : (isDarkMode ? '2px 4px 16px -3px rgba(0,0,0,0.55)' : '2px 4px 16px -3px rgba(0,0,0,0.15)'),
      
      p: 3,
      borderRadius: 2,
      backgroundColor: `${theme.palette.background.paper}`,
      ...props.sx
    }}
    onMouseEnter={props.onMouseEnter}
    onMouseOut={props.onMouseOut}
    onMouseOver={props.onMouseOver}
  >
    {children}
  </Box>;
};