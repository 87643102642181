import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import { MemoryRouter as Router } from 'react-router-dom';
import { AppBarLayout } from './Layout/AppBarLayout';
import { DrawerHeader, DrawerLayout } from './Layout/DrawerLayout';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { themeLight, themeDark } from './Theme/ThemeProvider';
import { useAppDispatch, useAppSelector } from './Infrastructure/Store/hooks';
import { useEffect } from 'react';
import { loadCsv } from './Infrastructure/Internationalisation/Store/LanguageFileSlice';
import { TourProvider } from '@reactour/tour';
import { LanguageConsumer } from './Infrastructure/Internationalisation/TranslationService';
import { handelRightClick } from './Infrastructure/AppUtility';
import { useGlobalSteps } from './Infrastructure/OnlineHelp/Global/OnlineHelpGlobalSteps';
import { DepragRoutes } from './DepragRoutes';
import { Toaster } from 'react-hot-toast';
import useMediaQuery from '@mui/material/useMediaQuery';
import AnimatedCursor from "react-animated-cursor"
import { ProjectDetailDrawer } from './Features/MainMenus/Projects/ProjectDetailDrawer';
import { toggleIsLoading } from './Store/AppSlice';
import { LoadingScreen } from './Infrastructure/LoadingScreen/LoadingScreen';
import { Contact } from './Features/MainMenus/Contact/Contact';




export default function App() {

  const dispatch = useAppDispatch();
  const isDarkTheme = useAppSelector(store => store.settings.theme === 'dark');
  const colorScheme = useAppSelector(store => store.settings.colorScheme);
  const { globalSteps } = useGlobalSteps();
  const theme = useTheme();
  const matchesSmallDevices = useMediaQuery(theme.breakpoints.up('md'));
  const isLoading = useAppSelector(store => store.global.isLoading);

  
  useEffect(() => {
    window.addEventListener('contextmenu', handelRightClick);
    dispatch(toggleIsLoading(true))
    dispatch(loadCsv());

    return () => {
      window.removeEventListener('contextmenu', handelRightClick);
    };
  }, []);


  return (<LanguageConsumer>
    {({getTranslatedText}) =>
    <div className="bg-container" style={{
      // background: `url(${'bg_white.svg'})`, 
      backgroundSize: 'cover', 
      height: '100%'
      }}>
   
    <TourProvider
    showCloseButton={false}
    padding={0}
    disableDotsNavigation={true}
    showBadge={false}
    showDots={false}
    scrollSmooth={true}
    disableInteraction={true}
    startAt={0}
    steps={globalSteps}
    >

      {/* {isLoading && <LoadingScreen />} */}
       
    <ThemeProvider theme={isDarkTheme ? themeDark(colorScheme) : themeLight(colorScheme)}>
    <AnimatedCursor
      innerSize={10}
      outerSize={20}
      color={isDarkTheme ? '255,255,255' : '0, 0, 0'}
      outerAlpha={0.2}
      innerScale={0.5}
      outerScale={2.5}
      outerStyle={{
        zIndex: 9999999,
      }}
      innerStyle={{
        zIndex: 9999999
      }}
    />

      <Box component={'div'} sx={{ display: 'flex'}}>
        <Router>

          <CssBaseline />

          <AppBarLayout />

          {/* matchesSmallDevices && <DrawerLayout /> */}

          <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 2, maxWidth: 'calc(100% - 60px)' }}>
            <DrawerHeader />

            <DepragRoutes />
            
            <Toaster />
            <ProjectDetailDrawer />
            <Contact />
          </Box>

        </Router>
      </Box>

    </ThemeProvider>
  </TourProvider>
    </div>
    }
  </LanguageConsumer>
  
  );
}
