
import { Box, Chip, Grid, Stack, Typography, useTheme } from '@mui/material';
import { LanguageConsumer } from '../../../Infrastructure/Internationalisation/TranslationService';
import './Style/Profile.css'
import { ProfileSplines } from './Components/ProfileSplines';
import { ProfileDrawer } from './Components/ProfileDrawer';

export const Profile = () => {
  const theme = useTheme();

  return (
    <LanguageConsumer>
      {({ getTranslatedText }) =>
        <>
          <Grid container direction="row" m={'auto'} spacing={1} sx={{height: '100%', mx: {xs: 2, md: 8, lg: 12}, mt: 1}}>
            <Grid item xs={5} alignContent={'center'} alignItems={'center'} alignSelf={'center'}>
              <Stack>
                <Typography variant="h2" fontWeight={200} color={'success'} sx={{mt: -5}}>{getTranslatedText("ProfileTitle")}</Typography>
                <Typography variant="h6" fontWeight={700} sx={{mt: 1}}>{getTranslatedText("ProfileSubTitle")}</Typography>
                <Box component={'div'} sx={{mt: 3}}>
                  <Chip label="React" sx={{mr: 1}} />
                  <Chip label="React Native" sx={{mr: 1}} />
                  <Chip label="Angular" sx={{mr: 1}} />
                  <Chip label="Ionic" sx={{mr: 1}} />
                  <Chip label=".NET" sx={{mr: 1}} />
                  <Chip label="C#" sx={{mr: 1}} />
                  <Chip label="PHP" />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={7} sx={{overflowY: 'hidden', height: 'calc(100vh - 140px)'}}>
                <>
                  <ProfileSplines />
                </>
            </Grid>
           
          </Grid>
        <ProfileDrawer />
        </>
      }
    </LanguageConsumer>
  );
};
