import Drawer from "@mui/material/Drawer"
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { Grid, SwipeableDrawer, Typography, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { toggleProjectDetailDrawer } from "../../../Store/AppSlice";
import { useEffect, useState } from "react";
import { ProjectListItem, ProjectsList } from "./Api/ProjectsProvider";

export const ProjectDetailDrawer = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const open = useAppSelector(store => store.global.projectDetailDrawerOpen);
    const projectName = useAppSelector(store => store.global.projectName);
    const [project, setProject] = useState<ProjectListItem>();

    const closeModal = () => {
        dispatch(toggleProjectDetailDrawer(''));
    }

    useEffect(() => {
        
       projectName !== '' && setProject(ProjectsList.filter(x => x.projectName === projectName)[0])

    }, [open])



    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
             <SwipeableDrawer
                onOpen={console.log}
                sx={{ zIndex: 12002 }}
                PaperProps={{ sx: { background: theme.palette.background.default, minWidth: 'auto', maxWidth: '50%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                anchor={'right'}
                open={open}
                onClose={closeModal}
            >
                <Grid container direction={'row'} spacing={2} sx={{p: 4}}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{getTranslatedText(project ? project.title : '')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <img draggable={false} alt="project" style={{maxWidth: '100%'}} src={`./Projects/${projectName}.jpg`} />
                    </Grid>
                </Grid>
            </SwipeableDrawer>
            </>
            }
        </LanguageConsumer>
    )
}