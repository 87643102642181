import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTranslation, LanguageConsumer } from '../../../../Infrastructure/Internationalisation/TranslationService';
import { useAppDispatch, useAppSelector } from '../../../../Infrastructure/Store/hooks';
import { CustomBox } from '../../../../Layout/CustomBox';
import { setAppTitle } from '../../../../Store/AppSlice';
import { ColorScheme, setAppTheme, setColorScheme, setLanguage  } from '../Store/SettingsSlice';

export const SettingsCommon = () => {
  const dispatch = useAppDispatch();
  const appTheme = useAppSelector((store) => store.settings.theme);
  const language = useAppSelector(store => store.settings.language);
  const colorScheme = useAppSelector(store => store.settings.colorScheme);

  const location = useLocation();

  useEffect(() => {
      dispatch(setAppTitle(getTranslation('SettingsBreadcrumbTitle')));
    }, [location, language]);

  return (<LanguageConsumer>
     {({ getTranslatedText }) => 
      <>
      <CustomBox>
    <Grid container direction="row" spacing={2} sx={{mt: 0}}>
    <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {getTranslatedText("Language")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            label={getTranslatedText("Language")}
            onChange={(e) => dispatch(setLanguage(e.target.value))}
          >
            <MenuItem value="de">{getTranslatedText("German")}</MenuItem>
            <MenuItem value="en">{getTranslatedText("English")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {getTranslatedText("AppTheme")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={appTheme}
            label={getTranslatedText("AppTheme")}
            onChange={(e) => dispatch(setAppTheme(e.target.value))}
          >
            <MenuItem value="light">{getTranslatedText("AppThemeLight")}</MenuItem>
            <MenuItem value="dark">{getTranslatedText("AppThemeDark")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
      <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {getTranslatedText("ColorScheme")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={colorScheme}
            label={getTranslatedText("ColorScheme")}
            onChange={(e) => dispatch(setColorScheme(e.target.value))}
          >
            <MenuItem value={ColorScheme.depragStandard}>{getTranslatedText("DepragStandard")}</MenuItem>
            <MenuItem value={ColorScheme.depragIndustrial}>{getTranslatedText("DepragIndustrial")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    
    </Grid>
    </CustomBox>

    </>
    }
    </LanguageConsumer>
  );
};
