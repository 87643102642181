import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { LanguageProvider } from './Infrastructure/Internationalisation/TranslationService';
import { persistor, store } from './Infrastructure/Store/store';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "typeface-league-script"
import "typeface-amatic-sc"
import "typeface-nunito-sans"
import "typeface-bebas-neue"
import "typeface-satisfy"
import { Box } from '@mui/material';


const container = document.getElementById('root')!;
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LanguageProvider>
          <QueryClientProvider client={queryClient}>
              <App />
          </QueryClientProvider>
        </LanguageProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

