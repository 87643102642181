
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

type LoginRequest = {
    username: string;
    password: string;
}

export type LoginState = {
  status: 'idle' | 'loading' | 'failed';
  authenticated: boolean;
  userToken: string;
  userId: number;
  username: string;
}

const initialState: LoginState = {
  status: 'idle',
  authenticated: false,
  userToken: "",
  userId: 0,
  username: ''
};

interface LoginResponse {
    userToken: string;
    authenticated: boolean
}


export const logIn = createAsyncThunk('authentication', async (request: LoginRequest, { rejectWithValue }) => {
    if(request.username === "admin" && request.password === "6211914054"){
            return {
                userToken: "25031989",
                authenticated: true,
                userId: 1,
                username: request.username
            }
    }    
        return {username: '', userToken: "",authenticated: false, userId: 0}
}
);


export const loginSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    removeAuthentication: (state) => {
        state.authenticated = false;
        state.userToken = "";
        state.userId = 0;
        state.username = '';
    }
  },
  extraReducers: builder => {
    builder.addCase(logIn.pending, (state) => {             
        state.status = 'loading';
    })
    .addCase(logIn.fulfilled, (state, action) => {                
        state.status = 'idle';                
        if((action.payload as any).authenticated){
            state.authenticated = true;
            state.userToken = (action.payload as any).userToken;
            state.userId = (action.payload as any).userId;
            state.username = (action.payload as any).username;
        } else {
            state.authenticated = false;
            state.userToken = "";
            state.userId = 0;
            state.username = '';
        }
    })
    .addCase(logIn.rejected, (state, action) => {   
        state.status = 'failed';                   
        state.authenticated = false;
        state.userToken = "";
        state.userId = 0;
        state.username = '';
    })
}
});

export const { removeAuthentication } = loginSlice.actions;


export default loginSlice.reducer;
