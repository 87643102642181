import { Grid, LinearProgress, Typography, linearProgressClasses, styled, useTheme} from "@mui/material";
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService"
import { CustomBox, CustomBoxHeader } from "../../../../Layout/CustomBox";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 8,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: 'linear-gradient(to right, #24EAC2, #FFD69E)',
    },
  }));

export const LifeSkills = () => {
    const theme = useTheme();

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
                <CustomBox noShadow sx={{width: 'calc(100% - 130px)', borderRadius: 0, pb: 5,  borderBottom: '1px solid', borderColor: '#b6d6d5'}}>
                <CustomBoxHeader text={getTranslatedText("HtmlCssJavascript")} sx={{fontWeight: 200, fontSize: '1.6em'}} />
                <Grid container direction={'row'} spacing={2} sx={{mt: -4}}>
                  <Grid item xs={12}>
                    {getTranslatedText("HtmlBodyText")}
                  </Grid>
                  <Grid item xs={12}>
                  <BorderLinearProgress variant="determinate" value={100} />
                  </Grid>
                </Grid>
              </CustomBox>

              <CustomBox noShadow sx={{width: 'calc(100% - 130px)', borderRadius: 0, pb: 5, mt: 2, borderBottom: '1px solid', borderColor: '#b6d6d5'}}>
                <CustomBoxHeader text={getTranslatedText("ReactAngular")} sx={{fontWeight: 200, fontSize: '1.6em'}} />
                <Grid container direction={'row'} spacing={2} sx={{mt: -4}}>
                  <Grid item xs={12}>
                    {getTranslatedText("ReactBodyText")}
                  </Grid>
                  <Grid item xs={12}>
                    <BorderLinearProgress variant="determinate" value={100} />
                  </Grid>
                </Grid>
              </CustomBox>

              <CustomBox noShadow sx={{width: 'calc(100% - 130px)', borderRadius: 0, pb: 5, mt: 2,  borderBottom: '1px solid', borderColor: '#b6d6d5'}}>
                <CustomBoxHeader text={getTranslatedText("CSharp")} sx={{fontWeight: 200, fontSize: '1.6em'}} />
                <Grid container direction={'row'} spacing={2} sx={{mt: -4}}>
                  <Grid item xs={12}>
                    {getTranslatedText("CSharpBodyText")}
                  </Grid>
                  <Grid item xs={12}>
                    <BorderLinearProgress variant="determinate" value={90} />
                  </Grid>
                </Grid>
              </CustomBox>

              <CustomBox noShadow sx={{width: 'calc(100% - 130px)', borderRadius: 0, pb: 5,  mt: 2,  border: '0px solid', borderColor: '#b6d6d5'}}>
                <CustomBoxHeader text={getTranslatedText("BackendPhpNode")} sx={{fontWeight: 200, fontSize: '1.6em'}} />
                <Grid container direction={'row'} spacing={2} sx={{mt: -4}}>
                  <Grid item xs={12}>
                    {getTranslatedText("BackendBodyText")}
                  </Grid>
                  <Grid item xs={12}>
                    <BorderLinearProgress variant="determinate" value={85} />
                  </Grid>
                </Grid>
              </CustomBox>
            </>
            }
        </LanguageConsumer>
    )
}