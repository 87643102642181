import { Modal, Box, Typography, Grid, TextField, Button } from "@mui/material"
import { lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { logIn } from "./Store/LoginSlice";
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from "@react-three/drei";
const AdfsModell = lazy(() => import('./Components/Adfs'));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '50%',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 0,
    outline: 'none'
};


export const Login = () => {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const isAuthenticated = useAppSelector((store) => store.authentication.authenticated);
    const navigate = useNavigate();

    const submitLoginForm = () => {
        dispatch(logIn({ username, password }))
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate('../');
        }
    }, [isAuthenticated])


    return (<LanguageConsumer>
        {({ getTranslatedText }) =>
            <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                    sx: {
                        backgroundColor: 'rgba(0,0,0,0.94)'
                    }
                }}
            >
                <Box component={'div'} sx={style}>
                    <Grid container direction="row">
                        <Grid item xs={6} sx={{ p: 10 }}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} sx={{ textAlign: 'center', mb: 5 }}>
                                    <Typography variant="h4" component={'h2'} fontWeight={800} sx={{ mt: 2 }}>
                                        DEPRAG GraphViewer CLOUD
                                    </Typography>
                                    <Typography>
                                        Manager
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        required
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        label={getTranslatedText("Username")}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        color="success"
                                        fullWidth
                                        type={'password'}
                                        required
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        label={getTranslatedText("Password")}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Button color='success' onClick={submitLoginForm} fullWidth variant='contained'>{getTranslatedText("LoginButton")}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" justifyContent="center" xs={6} sx={{ position: 'relative', textAlign: 'center', background: '#f0f5f1', borderTopRightRadius: 12, borderBottomRightRadius: 12 }}>
                            <img src={'logo.png'} alt="ADFS" style={{ padding: 30, position: 'relative', alignSelf: 'center', width: "100%", filter: 'drop-shadow(2px 2px 5px #444)'}} />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        }
    </LanguageConsumer>)
}