import Spline from '@splinetool/react-spline';
import { useCallback, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../Infrastructure/Store/hooks';
import { setLanguage, toggleTheme } from '../../Settings/Store/SettingsSlice';
import { Application, SplineEvent } from '@splinetool/runtime';

export const ProfileSplines = () => {
  const open = useAppSelector(store => store.global.drawerOpen);
  const stadtAmberg = useRef();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(store => store.global.isLoading)
  const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');
  const [showIt, setShowIt] = useState<boolean>(false);
  const splineRef = useRef(null);

  const oneMousePress = (e: SplineEvent) => {
    var object = e.target.name as string;
    
    if(object === 'Theme') {
      setAppThemeDispatch();
    }

    if(object === 'German'){
      dispatch(setLanguage('de'))
    }

    if(object === 'English'){
      dispatch(setLanguage('en'))
    }
  }

  const setAppThemeDispatch = useCallback(() => {
      dispatch(toggleTheme())
  }, [])
  
  function onLoad(spline: Application) {
   
    spline.setSize(0, 0);
   
    setTimeout(function () {
        setShowIt(true);
        if(splineRef && splineRef.current){
          spline.setSize((splineRef.current as any).offsetWidth, (splineRef.current as any).offsetHeight);
        }
      
    }, 500);
}

  return (
        <Spline
            ref={splineRef}
            onLoad={onLoad}
            onMouseUp={oneMousePress}
            renderOnDemand={true}
            style={{opacity: showIt ? 1 : 0, transition: 'opacity 0.3s ease-in-out', top: 0, left: 0, width: `100%`, height: '100%', zIndex: 100001}} 
            scene="scene.splinecode" />
          );
}