import { StyledComponentProps } from "@mui/material"
import Moment, { MomentProps } from "react-moment"
import { useAppSelector } from "../../Store/hooks"
import moment from "moment";
import 'moment/locale/de';
import { useEffect } from "react";

interface DepragMomentProps {
    currentDate: Date
}

interface Props extends MomentProps, DepragMomentProps, StyledComponentProps {}

export const DepragMoment = ({format, currentDate, ...props}: Props) => {
    const language = useAppSelector(store => store.settings.language);

    moment.locale(language);

    useEffect(() => {
        moment.locale(language);
    }, [language])

    return (
        <Moment format={format}>
            {currentDate}
        </Moment>
    )
}