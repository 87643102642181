import { useEffect } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../Infrastructure/Store/hooks'
import { motion, useIsPresent } from "framer-motion";

const ProtectedRoute = () => {
  const { userToken, authenticated } = useAppSelector((state) => state.authentication)
  const navigate = useNavigate();
  const isPresent = useIsPresent();
  
  // useEffect(() => {
  //   if(!authenticated){
  //       navigate('/login')
  //   }
  // }, [authenticated])

  // if (!authenticated || userToken === '') {
    
  //   return (
  //     <></>
  //   )
  // }

  return (
    <motion.main
    className="main__container"
    initial={{ y: -20, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{duration: 0.7,ease: [0.6, -0.05, 0.01, 0.99]}}
 >
  <Outlet />
  </motion.main>)
}
export default ProtectedRoute