import { CloseTwoTone, ImageAspectRatioTwoTone } from "@mui/icons-material";
import { Avatar, Box, Button, Chip, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { toggleNotificationsDrawer } from "./Store/NotificationsSlice";
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import DangerousTwoToneIcon from '@mui/icons-material/DangerousTwoTone';
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { SwipeableDrawer } from '@mui/material';
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone';
import * as React from 'react';
import { Link } from "react-router-dom";
import { CustomBox } from "../../../Layout/CustomBox";
import { DepragMoment } from "../../../Infrastructure/Internationalisation/Components/DepragMoment";

export const Notifications = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const openDashboardAddOrEdit = useAppSelector(store => store.notifications.open);
    const largeScreen = useMediaQuery(theme.breakpoints.up("md"))

    const closeModal = () => {
        dispatch(toggleNotificationsDrawer())
    }

    const notifications = [
        {id: 1, title: 'Drehmoment überschritten', type: 'Error', timeStamp: new Date()},
        {id: 2, title: 'Wartung Zahnriehmen steht an', type: 'Info', timeStamp: new Date()},
        {id: 3, title: 'Vorschubachse defekt', type: 'Error', timeStamp: new Date()},
        {id: 4, title: 'Austausch Beilagscheibe erforderlich', type: 'Warning', timeStamp: new Date()},
        {id: 5, title: 'Neue Information 1', type: 'Info', timeStamp: new Date()},
        {id: 6, title: 'Neue Information 2', type: 'Info', timeStamp: new Date()},
        {id: 7, title: 'Neue Warnung 1', type: 'Warning', timeStamp: new Date()},
        {id: 8, title: 'Neuer Fehler', type: 'Error', timeStamp: new Date()},
        {id: 9, title: 'Neue Warnung 2', type: 'Warning', timeStamp: new Date()},
    ]

    return(<LanguageConsumer>
        {({getTranslatedText}) => 
        <>
         <SwipeableDrawer
        disableSwipeToOpen={true}
        onOpen={console.log}
        sx={{zIndex: 12002}}
        PaperProps={{sx: { width: largeScreen ? 800 : '100%', background: theme.palette.background.paper, height: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20, overflow: 'hidden'}}}
        anchor={'right'}
        open={openDashboardAddOrEdit}
        onClose={closeModal}
      >
        <Box component={'div'} sx={{p: 4, height: '100%'}}>
        <Grid container direction={'row'} spacing={2}>
            <Grid item xs={10}>
                <Typography variant="h6" color={'secondary'}>{getTranslatedText('ActualSystemMessages')}</Typography>
            </Grid>
            <Grid item xs={2} sx={{textAlign: 'right'}} alignItems="flex-end">
                <IconButton onClick={closeModal}>
                    <CloseTwoTone />
                </IconButton>
            </Grid>
            
        </Grid>
        <Grid container direction={'row'} spacing={2} sx={{mt: 0}}>
            <Grid item xs={12}> 
                <Chip
                component={'div'}
                variant="outlined"
                clickable
                sx={{mr: 1, color: theme.palette.error.main, fontWeight: 800,borderWidth: 2, borderColor: theme.palette.error.main, p: 2.2}} 
                label={getTranslatedText("AcknowladgeAll")} 
                icon={<DoneAllTwoToneIcon sx={{  "&&": {color: theme.palette.error.main}}} />}
                />
                <Chip
                component={Link}
                to={'/system'}
                variant="outlined"
                clickable
                onClick={closeModal}
                sx={{mr: 1, color: theme.palette.secondary.main, fontWeight: 800,borderWidth: 2, borderColor: theme.palette.secondary.main, p: 2.2}} 
                label={getTranslatedText("GoToAllSystemMessages")} 
                />
            </Grid>
        </Grid>

        <Grid container direction={'row'} spacing={2} className={'verticalScroll'} sx={{mt: 1, maxHeight: 'calc(100% - 80px)', pb: 3, overflowY: 'auto'}}>
            
                {notifications.map((not, notKey) => {
                    var bgColor = theme.palette.error.light;
                    var bgColorChip = theme.palette.error.main;
                    var icon = <DangerousTwoToneIcon/>;
                    if(not.type === 'Info'){
                        bgColor = theme.palette.info.light;
                        bgColorChip = theme.palette.info.main;
                        icon = <InfoTwoToneIcon />;
                    } else if(not.type === 'Warning'){
                        bgColor = theme.palette.warning.light;
                        bgColorChip = theme.palette.warning.main;
                        icon = <ReportProblemTwoToneIcon />;
                    }

                    return (<Grid item xs={12}  key={`notification-${notKey}`} sx={{mt: 1}}>
                                <CustomBox sx={{minHeight: 100, height: '150px', m: 0, mr: 2, p: 0}}>
                                <Grid container direction={'row'} spacing={0} sx={{height: '100%'}}>
                                    <Grid item xs={4}>
                                        <Box component={'div'} sx={{ width: '100%', textAlign: 'center', background: bgColor, height: '100%', borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px'}}>
                                                <Stack  direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{width: '100%', textAlign: 'center'}}>
                                                    <Chip sx={{color: '#fff', background: bgColorChip, width: 100, mt: 1}} label={not.type} />
                                                
                                                </Stack>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid container direction={'row'} spacing={2} sx={{p: 2}}>
                                            <Grid item xs={6}>
                                                <Typography fontWeight={600} sx={{fontSize: 23, float: 'left', lineHeight: "23px", mr: 1}} color={'inherit'}> 
                                                <DepragMoment currentDate={not.timeStamp} format="HH:mm:ss" />
                                                </Typography>

                                                <Typography color={'inherit'} sx={{fontSize: 13, lineHeight: "23px", ml: 2}}> 
                                                <DepragMoment currentDate={not.timeStamp} format="DD.MM.yyyy" />
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container direction={'row'} spacing={2} sx={{p: 2, pt: 0}}>
                                            <Grid item xs={12}>
                                                <Typography variant={'h5'} fontWeight={200}>{not.title}</Typography>
                                            </Grid>
                                   
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </CustomBox>
                
                      
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
      </SwipeableDrawer>
        </>
        }
    </LanguageConsumer>
       
    );
}