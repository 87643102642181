import { Grid, StyledComponentProps, BoxProps, Chip, useTheme } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { LanguageConsumer } from '../Infrastructure/Internationalisation/TranslationService';

interface MenuEntry {
    name: string,
    route: string
}

interface SubmenuProps {
    menuEntrys: MenuEntry[]
}

interface Props extends BoxProps, SubmenuProps, StyledComponentProps {}

export const Submenu = ({ menuEntrys, ...props }: Props) => {
    const location = useLocation();
    const theme = useTheme();
    
  return (<LanguageConsumer>
    {({getTranslatedText}) =>
    <>
    <Grid container direction="row">
      <Grid item xs={12}>
        {menuEntrys.map((entry, key) => {
            const isActive = location.pathname === entry.route;
            return (
                <Chip
                component={NavLink}
                to={entry.route}
                variant="outlined"
                clickable
                sx={{mr: 1, color: isActive ? '#32992c' : theme.palette.primary.main, fontWeight: isActive ? 800 : 400,borderWidth: isActive ? 3 : 1, borderColor: isActive ? "#32992c": 'auto', p: 2.2}} 
                label={getTranslatedText(entry.name)} 
                key={key} />
            )
        })}
      </Grid>
    </Grid>
    </>
    }
    </LanguageConsumer>
  );
};
