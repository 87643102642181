import { Grid, Typography } from "@mui/material"
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { useAppSelector } from "../../../Infrastructure/Store/hooks"

export const Imprint = () => {
    const isDe = useAppSelector(store => store.settings.language === 'de');
    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <>
                  {isDe ?  <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            <Typography variant="h5">Meier Tobias, Rosenthal Jürgen GbR</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Vertretungsberechtigte Gesellschafter: Tobias Meier, Jürgen Rosenthal
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            <p>Bodelschwinghstraße 27</p>

                            <p>92224 Amberg</p>

                            <p>Deutschland</p>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                        <p>Tel.: +49 160 90920347</p>

                        <p>Mail: info@stadtrausch.com</p>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Umsatzsteuer-Identifikationsnummer: DE316664834
                        </Grid>



                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            <b>HAFTUNGSAUSSCHLUSS</b>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            1. Haftungsbeschränkung
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Die Inhalte des Internetauftritts wurden mit größtmöglicher Sorgfalt und nach bestem Gewissen erstellt. Dennoch übernimmt der Anbieter dieser Webseite keine Gewähr für die Aktualität, Vollständigkeit und Richtigkeit der bereitgestellten Seiten und Inhalte. Als Diensteanbieter ist der Anbieter dieser Webseite gemäß § 7 Abs. 1 TMG für eigene Inhalte und bereitgestellte Informationen auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich; nach den §§ 8 bis 10 TMG jedoch nicht verpflichtet, die übermittelten oder gespeicherten fremden Informationen zu überwachen. Eine Entfernung oder Sperrung dieser Inhalte erfolgt umgehend ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung. Eine Haftung ist erst ab dem Zeitpunkt der Kenntniserlangung möglich.
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            2. Externe Links
                        </Grid>
                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Die Webseite enthält sog. „externe Links“ (Verlinkungen) zu anderen Webseiten, auf deren Inhalt der Anbieter der Webseite keinen Einfluss hat. Aus diesem Grund kann der Anbieter für diese Inhalte auch keine Gewähr übernehmen. Für die Inhalte und Richtigkeit der bereitgestellten Informationen ist der jeweilige Anbieter der verlinkten Webseite verantwortlich. Zum Zeitpunkt der Verlinkung waren keine Rechtsverstöße erkennbar. Bei Bekanntwerden einer solchen Rechtsverletzung wird der Link umgehend entfernen.
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            3. Urheberrecht/Leistungsschutzrecht
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Die auf dieser Webseite veröffentlichten Inhalte, Werke und bereitgestellten Informationen unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Jede Art der Vervielfältigung, Bearbeitung, Verbreitung, Einspeicherung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedarf der vorherigen schriftlichen Zustimmung des jeweiligen Rechteinhabers. Das unerlaubte Kopieren/Speichern der bereitgestellten Informationen auf diesen Webseiten ist nicht gestattet und strafbar.
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            4. Datenschutz
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Durch den Besuch des Internetauftritts können Informationen (Datum, Uhrzeit, aufgerufene Seite) über den Zugriff auf dem Server gespeichert werden. Es werden keine personenbezogenenen (z. B. Name, Anschrift oder E-Mail-Adresse) Daten, gespeichert. Sofern personenbezogene Daten erhoben werden, erfolgt dies, sofern möglich, nur mit dem vorherigen Einverständnis des Nutzers der Webseite. Eine Weitergabe der Daten an Dritte findet ohne ausdrückliche Zustimmung des Nutzers nicht statt. Der Anbieter weist darauf hin, dass die Übertragung von Daten im Internet (z. B. per E-Mail) Sicherheitslücken aufweisen und ein lückenloser Schutz der Daten vor dem Zugriff Dritter nicht gewährleistet werden kann. Der Anbieter übernimmt keine Haftung für die durch solche Sicherheitslücken entstandenen Schäden. Der Verwendung der Kontaktdaten durch Dritte zur gewerblichen Nutzung wird ausdrücklich widersprochen. Es sei denn, der Anbieter hat zuvor seine schriftliche Einwilligung erteilt. Der Anbieter behält sich rechtliche Schritte für den Fall der unverlangten Zusendung von Werbeinformationen, z. B. durch Spam-Mails, vor.
                        </Grid>
                    </Grid> 
                    :
                    <Grid container direction={'row'} spacing={2} sx={{ mt: 1 }}>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            <Typography variant="h5">Meier Tobias, Rosenthal Jürgen GbR</Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Partners authorized to represent the company: Tobias Meier, Jürgen Rosenthal
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            <p>Bodelschwinghstraße 27</p>

                            <p>92224 Amberg</p>

                            <p>Germany</p>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                        <p>Tel.: +49 160 90920347</p>

                        <p>Mail: info@stadtrausch.com</p>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            Umsatzsteuer-Identifikationsnummer: DE316664834
                        </Grid>



                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            <b>DISCLAIMER</b>
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            1. limitation of liability
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            The contents of this website have been created with the greatest possible care and to the best of our knowledge. Nevertheless, the provider of this website does not guarantee the timeliness, completeness and accuracy of the pages and content provided. As a service provider, the provider of this website is responsible for its own content and information provided on these pages in accordance with § 7 paragraph 1 TMG under the general laws; according to § § 8 to 10 TMG, however, not obliged to monitor the transmitted or stored foreign information. A removal or blocking of this content will take place immediately from the time of knowledge of a concrete infringement. Liability is only possible from the time of knowledge.
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            2. external links
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            The website contains so-called "external links" (links) to other websites, on the content of which the provider of the website has no influence. For this reason, the provider cannot assume any liability for these contents. The respective provider of the linked website is responsible for the content and accuracy of the information provided. At the time of linking, no legal violations were apparent. Upon becoming aware of such a violation, the link will be removed immediately.
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            3. copyright/performance right
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            The content, works and information provided on this website are subject to German copyright and ancillary copyright laws. Any kind of reproduction, editing, distribution, storage and any kind of exploitation outside the limits of copyright law requires the prior written consent of the respective copyright holder. Unauthorized copying/saving of the information provided on these web pages is not permitted and is punishable by law.
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            4. data protection
                        </Grid>

                        <Grid item xs={12} sx={{ mx: { xs: 2, md: 8, lg: 12 }, mt: 1 }}>
                            By visiting the website, information (date, time, accessed page) about the access can be stored on the server. No personal data (e.g. name, address or e-mail address) is stored. If personal data is collected, this will only be done, if possible, with the prior consent of the user of the website. The data will not be passed on to third parties without the express consent of the user. The provider points out that the transmission of data on the Internet (eg by e-mail) security gaps and a complete protection of data against unauthorized access by third parties can not be guaranteed. The provider assumes no liability for any damage caused by such security gaps. The use of contact data by third parties for commercial use is expressly prohibited. Unless the provider has given prior written consent. The provider reserves the right to take legal action in the event of unsolicited sending of advertising information.
                        </Grid>


                    </Grid>
                    }
                </>
            }
        </LanguageConsumer>
    )
}