import { Grid } from "@mui/material"
import { ReactElement } from "react";
import { Submenu } from "../../../Layout/SubmenuLayout";

interface SettingsProps {
    component: ReactElement
}

const submenuEntrysSettings = [
    { name: 'SettingsCommonTitle', route: '/settings'},
    { name: 'SettingsIndustry40Title', route: '/settings/industry'},
]

export const Settings = ({component, ...props} : SettingsProps) => {

    return (
        <>
          <Submenu menuEntrys={submenuEntrysSettings} />

          <Grid container direction={'row'} spacing={2} sx={{mt: 2}}>
            <Grid item xs={12}>
                {component}
            </Grid>
          </Grid>
        </>
    )
}