import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, Button, Menu, MenuItem, Stack, Tooltip, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../Infrastructure/Store/hooks';
import { toggleDrawer } from '../Store/AppSlice';
import { Notifications } from '../Features/Infrastructure/Notifications/Notifications';
import { removeAuthentication } from '../Features/Infrastructure/Login/Store/LoginSlice';
import { Brightness5, ChevronLeft, ChevronRight, DarkMode, Instagram, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Language, LinkedIn, Logout, MenuSharp } from '@mui/icons-material';
import { setAppTheme, setLanguage } from '../Features/MainMenus/Settings/Store/SettingsSlice';
import { LanguageConsumer } from '../Infrastructure/Internationalisation/TranslationService';
import Flag from 'react-world-flags'
import { NavLink, useLocation } from 'react-router-dom';
import { toggleContact } from '../Features/MainMenus/Contact/Store/ContactSlice';

export const drawerWidth = 250;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppBarLayout = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector((store) => store.global.drawerOpen);
  const appTitle = useAppSelector((store) => store.global.appTitle);
  const isDarkMode = useAppSelector((store) => store.settings.theme === 'dark');
  const isLangDe = useAppSelector((store) => store.settings.language === 'de');
  const theme = useTheme();
  const [appBarBackground, setAppBarBackground] =
    useState<string>('transparent');
  const [scrollTop, setScrollTop] = useState<number>(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const matchesBigDevices = useMediaQuery(theme.breakpoints.up('md'));
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    function onScroll() {
      const currentPosition = window.pageYOffset;
      if (currentPosition > scrollTop) {
        setAppBarBackground(isDarkMode ? 'transparent' : 'transparent');
      } else if (currentPosition <= 10) {
        setAppBarBackground('transparent');
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <LanguageConsumer>
      {({getTranslatedText}) =>
       <>
       <AppBar
         position="fixed"
         open={open}
         sx={{
           width: '100vw',
           height: 70,
           top: 0,
           right: 0,
           transition: 'background 0.2s ease-in-out',
          // background: appBarBackground,
           m: 0,
           p: 0,
           zIndex: 1201,
         }}
       >
         <Toolbar sx={{mx: {xs: 2, md: 8, lg: 12}}}>
         
            <Box component={'div'}>
              <Typography variant='h6'>
                  <img src='logo_tm.png' style={{height: 30, float: 'left', marginRight: 10}} />
                  MEIER ROSENTHAL
              </Typography>
            </Box>


           <Box component="div" sx={{ marginLeft: 'auto' }} className={'global-toolbar'}>

            {!matchesBigDevices && <>
              <Tooltip title={getTranslatedText("ChangeLanguage")}>
             <IconButton
               aria-label="open drawer"
               onClick={handleClick}
               edge="start"
               sx={{
                 color: theme.palette.darky.main,
                 ml: 2
               }}
             >
               <MenuSharp />
             </IconButton>
             </Tooltip>

             <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{borderRadius: 3}}
              >
                <MenuItem component={NavLink} to={'/'} onClick={handleClose}>
                  <Typography>{getTranslatedText("Home")}</Typography>
                </MenuItem>

                <MenuItem component={NavLink} to={'/projects'} onClick={handleClose}>
                  <Typography>{getTranslatedText("MainMenuProjects")}</Typography>
                </MenuItem>

                <MenuItem component={NavLink} to={'/privacy'} onClick={handleClose}>
                  <Typography>{getTranslatedText("MainMenuPrivacy")}</Typography>
                </MenuItem>


                <MenuItem component={NavLink} to={'/imprint'} onClick={handleClose}>
                  <Typography>{getTranslatedText("MainMenuImprint")}</Typography>
                </MenuItem>

                <MenuItem component={'div'} onClick={() => {
                  handleClose();
                  dispatch(toggleContact());
                }}>
                  <Typography>{getTranslatedText("MainMenuContact")}</Typography>
                </MenuItem>
          
              </Menu>
            </> }
       
 
           {matchesBigDevices &&
           <>
             <Button component={NavLink} to='/' variant={'text'} color="darky" sx={{borderRadius: 0, mr: 3, mt: 1, borderBottom: `${location.pathname === '/' ? 3 : 0}px solid ${theme.palette.themey.main}`}}>
              <Typography fontWeight={700}>{getTranslatedText("Home")}</Typography>
            </Button>

            <Button component={NavLink} to='/projects' variant={'text'} color="darky" sx={{borderRadius: 0, mr: 3, mt: 1, borderBottom: `${location.pathname === '/projects' ? 3 : 0}px solid ${theme.palette.themey.main}`}}>
              <Typography fontWeight={700}>{getTranslatedText("MainMenuProjects")}</Typography>
            </Button>

            <Button component={NavLink} to='/privacy' variant={'text'} color="darky" sx={{borderRadius: 0, mr: 3, mt: 1, borderBottom: `${location.pathname === '/privacy' ? 3 : 0}px solid ${theme.palette.themey.main}`}}>
              <Typography fontWeight={700}>{getTranslatedText("MainMenuPrivacy")}</Typography>
            </Button>

            <Button component={NavLink} to='/imprint' variant={'text'} color="darky" sx={{borderRadius: 0, mr: 3, mt: 1, borderBottom: `${location.pathname === '/imprint' ? 3 : 0}px solid ${theme.palette.themey.main}`}}>
              <Typography fontWeight={700}>{getTranslatedText("MainMenuImprint")}</Typography>
            </Button>

             <Button onClick={() => dispatch(toggleContact())} variant="contained" color="themey" sx={{borderRadius: 10,  mt: .7}}>{getTranslatedText("MainMenuContact")}</Button>
{/* 
           <Tooltip title={getTranslatedText("ChangeLanguage")}>
             <IconButton
               aria-label="open drawer"
               onClick={handleClick}
               edge="start"
               sx={{
                 color: theme.palette.darky.main,
                 ml: 2
               }}
             >
               <Language />
             </IconButton>
             </Tooltip>

             <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                sx={{borderRadius: 3}}
              >
                <MenuItem onClick={() => dispatch(setLanguage('de'))}>
                <Flag code={ 'de' } height={16}  />
                  <Typography sx={{ml: 1, fontWeight: isLangDe ? 800 : 400}}>{getTranslatedText("German")}</Typography>
                  </MenuItem>
                <MenuItem onClick={() => dispatch(setLanguage('en'))}>
                  <Flag code={ 'us' } height={14}  />
                  <Typography sx={{ml: 1, fontWeight: !isLangDe ? 800 : 400}}>{getTranslatedText("English")}</Typography>
                  </MenuItem>
              </Menu> */}

               
            {/* {location.pathname !== '/' && <Tooltip title={getTranslatedText(isDarkMode ? "SetLightMode" : "SetDarkMode")}>
             <IconButton
               aria-label="open drawer"
               onClick={() => dispatch(setAppTheme(isDarkMode ? 'light' : 'dark'))}
               edge="start"
               sx={{
                 color: theme.palette.darky.main,
                 ml: 2
               }}
             >
               {isDarkMode ? <Brightness5 /> : <DarkMode />}
             </IconButton>
             </Tooltip>  } */}

             </>}

           </Box>
         </Toolbar>
       </AppBar>
 
       <Notifications />
     </>
      
      }
    </LanguageConsumer>
   
  );
};
