import { Grid, SwipeableDrawer, Typography, useTheme } from "@mui/material"
import { LanguageConsumer } from "../../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../../Infrastructure/Store/hooks";
import { toggleProfileDrawer } from "../../../../Store/AppSlice";

export const ProfileDrawer = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const open = useAppSelector(store => store.global.profileDrawerOpen);

    const closeModal = () => {
        dispatch(toggleProfileDrawer())
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
               <SwipeableDrawer
                onOpen={console.log}
                sx={{ zIndex: 12002 }}
                PaperProps={{ sx: {p: 4, background: theme.palette.background.default, minWidth: '50%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 } }}
                anchor={'right'}
                open={open}
                onClose={closeModal}
            >
                <Grid container direction={'row'} spacing={2}>
                    <Grid item xs={12}>
                       <Typography variant="h2">Tobias Meier</Typography>
                    </Grid>
                </Grid>
            </SwipeableDrawer>
            </>
            }
        </LanguageConsumer>
    )
}