import { Chip, Grid, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getTranslation, LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks";
import { setAppTitle } from "../../../Store/AppSlice";
import { CustomBox } from "../../../Layout/CustomBox";
import { LifeSkills } from "./Components/LifeSkills";
import './Style/Skills.css'

export const Skills = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const language = useAppSelector(store => store.settings.language);
    const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);
    const isDarkMode = useAppSelector(store => store.settings.theme === 'dark');
  
    useEffect(() => {
      dispatch(setAppTitle(getTranslation('MainMenuSkills')));
    }, [location, language, languageFileWasLoaded]);
  
  
    return (
      <LanguageConsumer>
        {({ getTranslatedText }) =>
          <>
            <Grid container direction="row" spacing={1} sx={{ ml: {xs: 2, md: 8, lg: 12}, mt: 3}}>
            <Grid item xs={12} md={12}>
                <LifeSkills />
              </Grid>

            </Grid>
  
          </>
        }
      </LanguageConsumer>
    );
}