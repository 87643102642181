import { createSlice } from '@reduxjs/toolkit';

export enum ColorScheme {
  depragStandard = 'depragStandard',
  depragIndustrial = 'depragIndustrial'
}

export interface SettingsState {
  status: 'idle' | 'loading' | 'failed',
  theme: 'dark' | 'light',
  language: string,
  colorScheme: ColorScheme,
}

const initialState: SettingsState = {
  status: 'idle',
  theme: 'light',
  language: 'en',
  colorScheme: ColorScheme.depragStandard
};


export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setColorScheme: (state, action) => {
      state.colorScheme = action.payload;
    },
    setLanguage: (state,action) => {
      state.language = action.payload
    },
    setAppTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === 'dark' ? 'light' : 'dark'
    }
  }
});

export const { toggleTheme, setLanguage, setAppTheme, setColorScheme } = settingsSlice.actions;


export default settingsSlice.reducer;
