
import { Action, combineReducers, configureStore, getDefaultMiddleware, ThunkAction } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from '../../Store/AppSlice';
import settingsReducer from '../../Features/MainMenus/Settings/Store/SettingsSlice';
import notificationsReducer from '../../Features/Infrastructure/Notifications/Store/NotificationsSlice';
import languageFileReducer from '../Internationalisation/Store/LanguageFileSlice';
import loginReducer from '../../Features/Infrastructure/Login/Store/LoginSlice';
import onlineHelpReducer from '../../Infrastructure/OnlineHelp/Store/OnlineHelpSlice';
import contactReducer from '../../Features/MainMenus/Contact/Store/ContactSlice';



const rootReducer = combineReducers({ 
  global: appReducer,
  settings: settingsReducer,
  notifications: notificationsReducer,
  languageFile: languageFileReducer,
  authentication: loginReducer,
  onlineHelp: onlineHelpReducer,
  contact: contactReducer
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['settings', 'authentication', 'global']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  })
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
