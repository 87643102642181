import { createTheme } from '@mui/material';
import { ColorScheme } from '../Features/MainMenus/Settings/Store/SettingsSlice';

const getColorsFromScheme = (colorScheme: ColorScheme) => {
  switch (colorScheme) {
    case ColorScheme.depragStandard:
      return { primaryColor: '#32992c', secondaryColor: '#567255'}
    case ColorScheme.depragIndustrial:
      return { primaryColor: '#2d9a9a', secondaryColor: '#999'}
  
    default:
      return { primaryColor: '#32992c', secondaryColor: '#567255'}
  }
}


export const themeLight = (colorScheme: ColorScheme) => {

  const { primaryColor, secondaryColor} = getColorsFromScheme(colorScheme)

  return createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor,
      contrastText: '#1E1E24',
    },
    secondary: {
      main: secondaryColor
    },
    success: {
      main: '#658864',
    },
    whitey: {
      main: '#fff'
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    darky: {
      main: '#444'
    },
    themey: {
      main: '#2493C2',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: `"Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'linear-gradient(0deg, rgba(233,238,236,1) 35%, rgba(255,255,255,1) 50%, rgba(233,238,236,1) 65%)',
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
       } 
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            transition: "all .3s ease-in-out",
            backgroundColor: "#65afc7",
            color: '#fff',
            zIndex: -1,
            "& .MuiListItemIcon-root": {
              transition: "all .3s ease-in-out",
              color: '#fff !important'
            }

          },
          "&.Mui-selected:hover": {
            backgroundColor: "#65afc7",
          },
          '&:hover': {
            color: '#444',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'transparent', 
          borderWidth: "1px",
          borderStyle: 'solid', 
          boxShadow: 'none',
          "&.Mui-disabled":{
            background: 'rgb(119, 142, 119)', 
            boxShadow: 'none',
            borderWidth: "1px",
            borderStyle: 'solid', 
            opacity: 1,
            color: '#fff',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 1
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid rgba(223,228,226,.8)'
        },
        colorPrimary: {
          backgroundColor: 'rgba(233,238,236,1)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'transparent',
          border: 'none',
        },
      },
    },
  },
})
};

export const themeDark = (colorScheme: ColorScheme) => {

  const { primaryColor, secondaryColor} = getColorsFromScheme(colorScheme)

  return createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: primaryColor,
      contrastText: '#1E1E24',
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: '#658864',
    },
    whitey: {
      main: '#fff'
    },
    background: {
      default: '#111',
      paper: '#222'
    },
    darky: {
      main: '#999'
    },
    themey: {
      main: '#2493C2',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: `"Nunito Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'linear-gradient(0deg, rgba(10,10,10,1) 35%, rgba(10,10,10,.9) 50%, rgba(10,10,10,1) 65%)',
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
       } 
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "& .MuiListItemIcon-root": {
            transition: "all .3s ease-in-out",
            color: '#fff !important'
          },
          "&.Mui-selected": {
            backgroundColor: "#65afc7",

            "&.MuiListItemIcon-root": {
              color: '#fff !important'
            }
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#65afc7",
          },
          '&:hover': {
            color: '#444',
          }
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
        colorPrimary: {
          backgroundColor: 'rgba(10,10,10,1)',
          opacity: 1,
          backgroundImage: 'none',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: 'transparent', 
          borderWidth: "1px",
          borderStyle: 'solid', 
          boxShadow: 'none',
          "&.Mui-disabled":{
            background: 'rgb(119, 142, 119)', 
            boxShadow: 'none',
            borderWidth: "1px",
            borderStyle: 'solid', 
            opacity: 1,
            color: '#fff',
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            opacity: 1
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: 'transparent',
          border: 'none',
        },
      },
    },
  },
})
};

declare module '@mui/material/styles' {

  interface Palette {
    whitey: Palette['primary'];
    darky: Palette['primary'];
    themey: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    whitey?: PaletteOptions['primary'];
    darky?: PaletteOptions['primary'];
    themey?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    whitey: true;
    darky: true;
    themey: true;
  }
}

// Update the Button's color prop options
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    whitey: true;
    darky: true;
    themey: true;
  }
}


declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    whitey: true;
    darky: true;
    themey: true;
  }
}

  declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
      whitey: true;
      darky: true;
      themey: true;
    }
}