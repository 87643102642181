import { Chip, Grid, SwipeableDrawer, Typography, useTheme } from "@mui/material";
import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService"
import { useAppDispatch, useAppSelector } from "../../../Infrastructure/Store/hooks"
import { toggleContact } from "./Store/ContactSlice";
import { NavLink } from "react-router-dom";

export const Contact = () => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const open = useAppSelector(store => store.contact.open);

    const onClose = () => {
        dispatch(toggleContact())
    }

    return(
        <LanguageConsumer>
            {({getTranslatedText}) =>
            <>
             <SwipeableDrawer
                        anchor="right"
                         sx={{ zIndex: 12002 }}
                         PaperProps={{ sx: { background: theme.palette.background.default, minWidth: '30%', maxWidth: '50%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20, p: 5 } }}
                        open={open}
                        onClose={onClose}
                        onOpen={console.log}
                    >
                        <Grid container direction={'row'} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">{getTranslatedText("ItIsTimeToMeet")}</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{mt: 1}}>
                                <Typography variant="h6">{getTranslatedText("BeBrave")}</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{my: 4}}>
                                <img alt="Contact" title="Contact" src={'logo_tm.png'} style={{height: 120}} />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight={800}>Meier Tobias, Rosenthal Jürgen GbR</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{mt: 1}}>
                                <Typography variant="h6">Bodelschwinghstr. 27</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{mt: 1}}>
                                <Typography variant="h6">92224 Amberg</Typography>
                            </Grid>

                            <Grid item xs={12} sx={{mt: 1}}>
                                <Chip component={NavLink} to={'mailto:info@stadtrausch.com'} label={'info@stadtrausch.com'} />

                                <Chip sx={{ml: 1}} component={NavLink} to={'tel:+49 160 90920347'} label={'+49 160 90920347'} />
                            </Grid>
                        </Grid>
                    </SwipeableDrawer>
            </>
            }
        </LanguageConsumer>
    )
}