import { Grid, Typography, Button } from "@mui/material";
import { StepType } from "@reactour/tour";
import { getTranslation } from "../../Internationalisation/TranslationService";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";

export const useGlobalSteps = () => {
  const dispatch = useAppDispatch();

  const globalSteps = [
    // INTRO
    {
     position: 'center',
     stepInteraction: false,
     content: ({ setCurrentStep, setIsOpen  }) => (<div>
       <Typography sx={{ fontSize: 24, pb: 2, color: '#444' }}>{getTranslation("OnlineHelpInitialTitle")}</Typography>
       <div style={{color: '#444'}} dangerouslySetInnerHTML={{ __html: getTranslation("OnlineHelpInitialMessage") }}></div>
     </div>)
   },
     {
       selector: '.global-menu',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-process',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuProcessTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuProcessMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-programs',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuProgramsTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuProgramsMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-synoptic',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuSynopticTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuSynopticMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-results',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuResultsTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuResultsMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-system',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuSystemTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuSystemMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-maintenance',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuMaintenanceTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuMaintenanceMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-service',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuServiceTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuServiceMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-store',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuStoreTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuStoreMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-settings',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuSettingsTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuSettingsMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-close',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuCloseTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuCloseMessage")}}></div></div>),
       position: 'right'
     },
     {
       selector: '.global-menu-profile',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalMainMenuProfileTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalMainMenuProfileMessage")}}></div></div>),
       position: 'right'
     },
 
 
     {
       selector: '.global-toolbar',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalToolbarTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalToolbarMessage")}}></div></div>),
       position: 'left',
       padding: { mask: [30, 5]}
     },
     {
       selector: '.global-toolbar-help',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalToolbarHelpTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalToolbarHelpMessage")}}></div></div>),
       position: 'bottom',
     },
     {
       selector: '.global-toolbar-manualMode',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalToolbarManualModeTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalToolbarManualModeMessage")}}></div></div>),
       position: 'bottom',
     },
     {
       selector: '.global-toolbar-hardwareButtons',
       content: () => ( <div><Typography sx={{fontSize: 24, pb: 2, color: '#444'}}>{getTranslation("OnlineHelpGlobalToolbarHardwareButtonsTitle")}</Typography><div  style={{color: '#444'}} dangerouslySetInnerHTML={{__html: getTranslation("OnlineHelpGlobalToolbarHardwareButtonsMessage")}}></div></div>),
       position: 'bottom',
     },
   ] as StepType[]

   return {
    globalSteps
   }

}

